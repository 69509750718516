import _ from "lodash";
import * as React from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import Contribution from "./contribution";

export class ContributionValue {
  value: number | null;
  date: Date | null;
}

interface ContributionsPropsType {
  onValue: (values: Array<ContributionValue>) => void;
}

export default function Contributions({ onValue }: ContributionsPropsType) {
  const nextID = React.useRef<number>(0);

  const [contributionIDs, setContributionIDs] = React.useState<
    Record<number, boolean>
  >({
    [(nextID.current += 1)]: true,
  });

  const [values, setValues] = React.useState<Record<string, ContributionValue>>(
    {}
  );

  React.useEffect(() => {
    onValue(_.values(values));
  }, [onValue, contributionIDs, values]);

  const removeContribution = (id: number) => {
    setContributionIDs((prevContributionIDs) => {
      const newContributions = {
        ...prevContributionIDs,
      };
      delete newContributions[id];
      return newContributions;
    });
  };

  const addContribution = (deposit: boolean) => {
    setContributionIDs((prevContributionIDs) => {
      return {
        ...prevContributionIDs,
        [(nextID.current += 1)]: deposit,
      };
    });
  };

  const style: Record<string, React.CSSProperties> = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    contributions: {},
    buttons: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    button: {
      display: "flex",
      flexDirection: "row",
    },
    plus: {
      marginRight: "4px",
      fontWeight: "bold",
    },
  };

  const updateContribution = React.useCallback(
    (id: number, date: Date | null, val: number | null) => {
      setValues((prev) => {
        return {
          ...prev,
          [id]: {
            value: val,
            date: date,
          },
        };
      });
    },
    []
  );

  return (
    <div style={style.container}>
      <div style={style.contributions}>
        {_.map(contributionIDs, (deposit: boolean, id: number) => {
          return (
            <Contribution
              deposit={deposit}
              key={`contribution${id}`}
              onDestroy={() => {
                removeContribution(id);
              }}
              onValue={(date: Date | null, val: number | null) =>
                updateContribution(id, date, val)
              }
            />
          );
        })}
      </div>
      <div style={style.buttons}>
        <button
          className="pressable"
          style={style.button}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            addContribution(true);
          }}
        >
          <FiPlusCircle style={style.plus} color="green" /> Deposit
        </button>
        <button
          className="pressable"
          style={style.button}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            addContribution(false);
          }}
        >
          <FiMinusCircle style={style.plus} color="red" /> Withdrawal
        </button>
      </div>
    </div>
  );
}
