import * as React from "react";
import { BsTrash } from "react-icons/bs";
import MoneyInput from "./money_input";
import DateInput from "./date_input";

export default function Contribution({
  deposit,
  onDestroy,
  onValue,
}: {
  deposit: boolean;
  onDestroy: Function;
  onValue: (date: Date | null, value: number | null) => void;
}) {
  const date = React.useRef<Date | null>(null);
  const value = React.useRef<number | null>(null);

  const styles: Record<string, React.CSSProperties> = {
    container: {
      display: "flex",
      alignItems: "center",
      marginBottom: "1em",
    },
    input: {
      border: "none",
      borderBottom: "1px solid black",
      height: "2.5em",
      marginRight: "0.5em",
      textAlign: "right",
    },
    date: {},
  };

  const publish = React.useCallback(() => {
    onValue(date.current, value.current);
  }, [onValue]);

  const setDate = React.useCallback(
    (d: Date | null) => {
      date.current = d;
      publish();
    },
    [publish]
  );

  const setValue = React.useCallback(
    (v: number | null) => {
      value.current = v;
      publish();
    },
    [publish]
  );

  return (
    <div style={styles.container}>
      <DateInput
        required
        style={{
          ...styles.input,
          ...styles.date,
        }}
        type="date"
        min="1993-01-29"
        onValue={(date: Date | null) => {
          setDate(date);
        }}
      />
      <MoneyInput
        required
        deposit={deposit}
        style={styles.input}
        onValue={(val: number | null) => {
          setValue(val);
        }}
      />
      <button
        className="pressable"
        onClick={() => {
          onDestroy();
        }}
      >
        <BsTrash></BsTrash>
      </button>
    </div>
  );
}
