import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Contributions, { ContributionValue } from "components/contributions";
import MoneyInput from "components/money_input";
import Prompt from "components/prompt";
import { compressBase64 } from "lib/compression";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export default function Performance() {
  const navigate = useNavigate();

  const accountValue = React.useRef<number | null>(null);
  const contributions = React.useRef<Array<ContributionValue>>([]);

  const onContributionsChange = (values: Array<ContributionValue>) => {
    contributions.current = values;
  };

  const onAccountValueChange = (val: number | null) => {
    accountValue.current = val;
  };

  const submit = (event: React.FormEvent) => {
    const result = (function () {
      if (accountValue.current == null) {
        console.log("No account value");
        event.preventDefault();
        return false;
      }

      if (contributions.current.length === 0) {
        console.log("contributions missing");
        event.preventDefault();
        return false;
      }

      if (
        _.reduce(
          contributions.current,
          (err, cont) => {
            return err || cont.value === null || cont.date === null;
          },
          false
        )
      ) {
        console.log("contributions incomplete");
        return false;
      }

      const data = {
        c: contributions.current,
        v: accountValue.current,
      };

      const d = compressBase64(JSON.stringify(data));
      navigate(`/performance/results?d=${encodeURIComponent(d)}`);
    })();

    if (!result) {
      event.preventDefault();
    }
  };

  const styles: Record<string, React.CSSProperties> = {
    header: {
      textTransform: "uppercase",
    },
    subheader: {
      marginTop: "16px",
    },
    root: {
      gap: "40px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
    },
    form: {
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      textAlign: "center",
    },
  };

  return (
    <div style={styles.root}>
      <Box>
        <h1 style={styles.header}>Are you beating the market?</h1>
        <h6 style={styles.subheader}>(you're probably not)</h6>
      </Box>
      <Box>
        <LargeTooltip
          title="Enter your account contribution amounts & dates to compare your porfolio's performance."
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          placement="top"
        >
          <Box style={{ textDecoration: "underline", cursor: "pointer" }}>
            Instructions
          </Box>
        </LargeTooltip>
      </Box>
      <Box component="form" style={styles.form} onSubmit={submit}>
        <Prompt question={"Enter your account contribution amounts & dates."}>
          <Contributions onValue={onContributionsChange} />
        </Prompt>
        <Prompt question={"What is your total account balance today?"}>
          <MoneyInput
            required
            style={
              {
                textAlign: "center",
              } as React.CSSProperties
            }
            onValue={onAccountValueChange}
          />
        </Prompt>

        <Button type="submit" variant="contained">
          Calculate Performance
        </Button>
      </Box>
    </div>
  );
}

const LargeTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: 16,
    maxWidth: 220,
    border: "1px solid #dadde9",
  },
}));
