import { deflate, inflate } from "pako";
import { Buffer } from "buffer";

export const compressBase64 = (str: string): string => {
  return Buffer.from(deflate(str)).toString("base64");
};

export const decompressBase64 = (str: string): string => {
  return new TextDecoder().decode(inflate(Buffer.from(str, "base64")));
};
